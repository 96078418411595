import { ClientLogger } from 'bernie-client';
import NavigationStore from './navigation-store';
import ClientStoreManagement from './client-store-management';
import { ConfigStore } from './config-store';
import { SubAppConfigStore } from './sub-app-config-store';
import SubAppErrorStore from './sub-app-error-store';
import { UserStore } from './user-store';
import { BannerStore } from './banner-store';
import { PartnerStore } from './partner-store';
import { PlatformContextStore } from './platform-context-store';
import ScrimStore from './scrim-store';

export const clientStoreManagement = ClientStoreManagement.create()
  .withStore('config', () => new ConfigStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.config')))
  .withStore('bannerStore', () => new BannerStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.banner')))
  .withStore('partner', () => new PartnerStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.partner')))
  .withStore('subAppConfig', () => SubAppConfigStore.createForClient())
  .withStore(
    'navigation',
    () => new NavigationStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.navigation'))
  )
  .withStore(
    'subAppErrorStore',
    () => new SubAppErrorStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.subAppErrorStore'))
  )
  .withStore('user', () => new UserStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.user')))
  .withStore(
    'platformContext',
    () => new PlatformContextStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.platformContext'))
  )
  .withStore(
    'scrimStore',
    () => new ScrimStore(ClientLogger.getLoggerWithIdentifier('platformconsole.store.scrimStore'))
  );

export const stores = clientStoreManagement.clientFactories;

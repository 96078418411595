import { Logger } from '@shared-ui/logger-context/dist/commonjs/components/logger';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { LogFunction, ServerLogger } from './logging/server-logger';

declare module 'axios' {
  interface AxiosRequestConfig {
    operation?: string;
    traceId?: string;
    startTime?: number;
  }
}

const getResponseLog = ({ status, config, data }: AxiosResponse) => {
  const { url, method, operation, traceId, startTime } = config;
  const latency = startTime ? Date.now() - startTime : undefined;
  return {
    statusCode: status,
    method,
    response: {
      body: data,
    },
    traceId,
    url,
    latency,
    transactionType: 'RESPONSE',
    operation,
  };
};

const getRequestLog = ({ url, method, operation, traceId, data }: AxiosRequestConfig) => ({
  method,
  request: {
    body: data,
  },
  traceId,
  url,
  transactionType: 'REQUEST',
  operation,
});

const logError = (error: AxiosError, logger: Logger) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    logger.error('AXIOS_TRANSACTION', {
      ...getRequestLog(error.config),
      ...getResponseLog(error.response),
    });
  } else if (error.request) {
    // The request was made but no response was received
    logger.error('AXIOS_TRANSACTION', {
      ...getRequestLog(error.config),
    });
  } else {
    // Something happened in setting up the request that triggered an Error
    logger.error('AXIOS_TRANSACTION', {
      errorMessage: error.message,
    });
  }
};

async function httpRequest<T>(config: AxiosRequestConfig, log: LogFunction) {
  const logger = ServerLogger(log);

  // eslint-disable-next-line no-param-reassign
  config.startTime = Date.now();
  logger.info('AXIOS_TRANSACTION', getRequestLog(config));

  try {
    const response: AxiosResponse<T> = await axios(config);
    logger.info('AXIOS_TRANSACTION', getResponseLog(response));
    return response;
  } catch (e) {
    const error = e as AxiosError;
    logError(error, logger);
    throw error;
  }
}

export async function get<T>(config: AxiosRequestConfig, log: LogFunction) {
  return httpRequest<T>({ ...config, method: 'get' }, log);
}

export async function post<T>(config: AxiosRequestConfig, log: LogFunction): Promise<AxiosResponse<T>> {
  return httpRequest<T>({ ...config, method: 'post' }, log);
}

export const isAxiosError = (b: unknown): b is AxiosError => {
  return (b as AxiosError).isAxiosError !== undefined;
};

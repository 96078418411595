import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class ProductSelectionController extends DefaultController {
  public pageTitle = 'Product Selection';

  public pageId = 'product-selection';

  public path = EgPagePath.PRODUCT_SELECTION;

  public routeName = 'product-selection';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(
    () => import(/* webpackChunkName: "ProductDetails" */ '../views/product-selection-page/product-selection-page')
  );

  public exact = true;
}

import fetch from 'isomorphic-fetch';
import { getConfig } from 'bernie-config';

export interface SubAppManifest {
  subApps: LiveSubAppConfig[];
  subAppAssets: SubAppAssetConfig;
}

export interface SubAppAssetConfig {
  releaseVersionTemplate: string;
  entryPointTemplate: string;
}

export interface LiveSubAppConfig {
  appName: string;
  entry?: string;
  githubUrl: string;
  isEnabled: boolean;
  productId?: string;
  productKey?: string;
  routeId: string;
}

export interface SubAppConfig extends LiveSubAppConfig {
  endpoint: string;
  version?: string;
}

const POLL_PERIOD_MS = 60000;

export const subAppsConfig: Record<string, SubAppConfig> = {};

const delayPollPeriod = () => new Promise((resolve) => setTimeout(resolve, POLL_PERIOD_MS));

const refreshLiveSubAppConfig = async (subApp: LiveSubAppConfig, subAppAssets: SubAppAssetConfig) => {
  try {
    const { appName, entry } = subApp;
    const configUrl = subAppAssets.releaseVersionTemplate.replace('{appName}', appName).concat(`?ts=${Date.now()}`);
    const response = await fetch(configUrl);
    const { version } = await response.json();
    const endpoint =
      entry ?? subAppAssets.entryPointTemplate.replace('{appName}', appName).replace('{version}', version);
    subAppsConfig[appName] = { ...subApp, appName, version, endpoint };
  } catch (err) {
    // eslint-disable-next-line
    console.error(
      'logType="console::alert::refresh-live-sub-app-config"',
      `message="error refreshing release config for sub-app: ${subApp.appName}"`,
      err
    );
  }
};

const pollLiveSubAppConfigsLoop = async (): Promise<void> => {
  let subAppManifest: SubAppManifest | null = null;
  try {
    console.log('logType="console::info"', 'message="fetching sub-app metadata from sub-app-experience-api"');
    // fetch sub-app metadata from sub-app-experience-api every poll period
    const metadataResp = await fetch(`${getConfig().app.subAppExperienceApi.toString()}/api/sub-apps/metadata`);
    subAppManifest = await metadataResp?.json();
  } catch (err) {
    console.error(
      'logType="console::alert::metadata-fetch-failed"',
      `message="error fetching sub-app metadata from sub-app-experience-api"`,
      err
    );
  }

  if (!subAppManifest) {
    await delayPollPeriod();
    await pollLiveSubAppConfigsLoop();
    return;
  }

  const enabledSubApps: Array<LiveSubAppConfig> = subAppManifest.subApps.filter(
    (subApp: LiveSubAppConfig) => subApp.isEnabled
  );

  // refresh sub-app configs for all enabled sub-apps
  await Promise.all(enabledSubApps.map((subApp) => refreshLiveSubAppConfig(subApp, subAppManifest!.subAppAssets)));
  await delayPollPeriod();
  await pollLiveSubAppConfigsLoop();
};

export const pollLiveSubAppConfigs = async (): Promise<void> => {
  pollLiveSubAppConfigsLoop().catch(async (err) => {
    // eslint-disable-next-line
    console.error(
      'logType="console::alert::poll-live-sub-app-configs-unhandled-error"',
      `message="error polling for sub-app release config (retrying in ${POLL_PERIOD_MS}ms)"`,
      err
    );
    await delayPollPeriod();
    pollLiveSubAppConfigs();
  });
};

/**
 * The error object provided for exclusive use in `eg-platform-console`. See our {@link https://pages.github.expedia.biz/eg-control-plane/eg-console-docs/conventions/logging/#egconsolesubapperror | dev guide on error handling in sub-apps} for more information.
 *
 * @internal
 */
export class EgConsoleError extends Error {
  eventName!: string;

  wrappedError?: Error;

  constructor(eventName: string, wrappedError?: Error) {
    if (wrappedError instanceof EgConsoleError) {
      return wrappedError;
    }

    if (wrappedError) {
      /* istanbul ignore next */
      super(wrappedError.message);
    } else {
      /* istanbul ignore next */
      super(eventName);
    }

    Object.setPrototypeOf(this, EgConsoleError.prototype);

    this.eventName = eventName;
    this.wrappedError = wrappedError;
  }
}

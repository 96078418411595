import { ProductConfigsController } from './product-configs-controller';
import { PartnerHierarchyController } from './partner-hierarchy-controller';
import { HomeController } from './home-controller';
import { SubAppController } from './sub-app-controller';
import { TestSubAppsController } from './test-sub-apps-controller';
import { ProductCatalogController } from './product-catalog-controller';
import { PartnerSignUpController } from './partner-sign-up-controller';
import { AccountSelectionController } from './account-selection-controller';
import { DebugPageController } from './debug-page-controller';
import { WelcomePageController } from './welcome-page-controller';
import { ProfileController } from './profile-controller';
import { ProductDetailsController } from './product-details-controller';
import { CookiePolicyController } from './legal/cookie-policy-controller';
import { LogoutController } from './logout-controller';
import { ErrorPageController } from './error-page-controller';
import { ProductSelectionController } from './product-selection-controller';
import { BillingCenterController } from './billing-center-controller';
import { ProductStatusController } from './product-status-controller';
import { UserManagementController } from './user-management-controller';
import { ProductSupportController } from './product-support-controller';

export const controllers = [
  ProductSupportController,
  AccountSelectionController,
  BillingCenterController,
  CookiePolicyController,
  DebugPageController,
  ErrorPageController,
  HomeController,
  LogoutController,
  PartnerHierarchyController,
  PartnerSignUpController,
  ProductCatalogController,
  ProductConfigsController,
  ProductDetailsController,
  ProductSelectionController,
  ProductStatusController,
  ProfileController,
  SubAppController,
  TestSubAppsController,
  UserManagementController,
  WelcomePageController,
];

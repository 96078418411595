module.exports = {
  Console: {
    queryType: true,
  },
  /*
    The keyFields property allows us to customize how the cache IDs are generated. 
    An empty array denotes that there are no standard IDs and that all queries should be merged under the same key.
    https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids
  */
  ProductQuery: {
    keyFields: [],
  },
  PartnerQuery: {
    keyFields: [],
  },
  Documentation: {
    keyFields: [],
  },
  SessionManagerQuery: {
    keyFields: [],
  },
};

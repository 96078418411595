
      module.exports = {
  "possibleTypes": {
    "AIWorkbenchStepper": [
      "AiWorkbenchModelLifecycleStepper"
    ],
    "AccountCardFormButtonTrigger": [
      "ConsoleConfirmAddAccountButtonTrigger",
      "ConsoleConfirmEditAccountButtonTrigger"
    ],
    "AccountCardFormButtons": [
      "ConsoleConfirmAddAccountButton",
      "ConsoleConfirmEditAccountButton"
    ],
    "BillingProfileButtonAction": [
      "BillingPaymentMethodButtonAction",
      "BillingPaymentProfileButtonAction"
    ],
    "BillingProfileOptionValue": [
      "BillingProfileOptionValueKeyMap",
      "BillingProfileOptionValueRedirectionUrl"
    ],
    "BusinessHourDataView": [
      "BusinessHourView",
      "BusinessHoursErrorView"
    ],
    "BusinessHoursDataView": [
      "BusinessHoursErrorView",
      "BusinessHoursListView"
    ],
    "CMT_ItemData": [
      "CMT_ComponentData",
      "CMT_ProductData"
    ],
    "CollectionCoverageResponse": [
      "CollectionCoverageAvailable",
      "CollectionCoverageUnavailable"
    ],
    "CollectionDashboardResponse": [
      "CollectionDashboardAdoption"
    ],
    "CollectionFormControls": [
      "CollectionFormLayout",
      "CollectionFormTabs",
      "CollectionJSONData"
    ],
    "CollectionFormItem": [
      "CollectionButton",
      "CollectionCopyButton",
      "CollectionFlagText",
      "CollectionFormChildren",
      "CollectionFormItemBrands",
      "CollectionFormItemDestPerPos",
      "CollectionFormItemDistributionFilters",
      "CollectionFormItemMaxPrices",
      "CollectionFormSheet",
      "CollectionFormSpacer",
      "CollectionInputCheckbox",
      "CollectionInputEmail",
      "CollectionInputNumber",
      "CollectionInputPayloadPills",
      "CollectionInputPills",
      "CollectionInputRadio",
      "CollectionInputSelect",
      "CollectionInputSlider",
      "CollectionInputSwitch",
      "CollectionInputText",
      "CollectionInputTextarea",
      "CollectionInputTypeAhead",
      "CollectionToggleButton"
    ],
    "CollectionFormItemCondition": [
      "CollectionFormItemConditionInValues",
      "CollectionFormItemConditionMinLength",
      "CollectionFormItemConditionPresent",
      "CollectionFormItemConditionValue"
    ],
    "CollectionFormItemValue": [
      "CollectionFormItemStringValue",
      "CollectionFormItemStringValues"
    ],
    "CollectionMenuListItemIcon": [
      "Icon"
    ],
    "CollectionMenuTrigger": [
      "UIFloatingButton",
      "UIPrimaryButton",
      "UISecondaryButton",
      "UITertiaryButton"
    ],
    "CollectionSettingsDetailsListItem": [
      "CollectionFlagText",
      "EGDSSpannableText"
    ],
    "CollectionSheet": [
      "CollectionFormSheet"
    ],
    "CollectionTableCell": [
      "CollectionTableCellButtonGroup",
      "CollectionTableCellDate",
      "CollectionTableCellFlagText",
      "CollectionTableCellImage",
      "CollectionTableCellInputNumber",
      "CollectionTableCellMenu",
      "CollectionTableCellText",
      "CollectionTableCellTextInput"
    ],
    "CollectionsHeaderResponse": [
      "CollectionsPageHeadingV2"
    ],
    "ConsoleAction": [
      "ConsoleActionLink",
      "ConsoleProductDetailsAction",
      "ConsoleSandboxCTAActionLink"
    ],
    "ConsoleAsideCardTrigger": [
      "ConsoleGoToPartnerProfile"
    ],
    "ConsoleBillingCenterButtonAction": [
      "ConsoleBillingCenterDownloadSelected",
      "ConsoleBillingCenterExportCSV",
      "ConsoleConsoleBillingCenterDownloadPDF",
      "ConsoleConsoleBillingCenterPreviewPDF"
    ],
    "ConsoleBillingProfileCreationFormResponse": [
      "BillingProfileCreationFormData",
      "PartnerPaymentsErrorList"
    ],
    "ConsoleBillingProfileCreationResponse": [
      "ConsoleBillingProfileSuccessBanner",
      "PartnerPaymentsErrorList"
    ],
    "ConsoleBillingSubscriptionFormField": [
      "EGDSBasicSelect",
      "EGDSNumberInputField",
      "EGDSSingleDatePicker",
      "EGDSTextInputField"
    ],
    "ConsoleBillingSubscriptionFormFieldContainer": [
      "ConsoleBillingSubscriptionFormBasicContainer",
      "ConsoleBillingSubscriptionFormDataPackContainer"
    ],
    "ConsoleCard": [
      "ConsoleProductCard",
      "ConsoleSimpleCard"
    ],
    "ConsoleChart": [
      "ConsoleCartesianChart",
      "ConsoleGaugeChart",
      "ConsolePieChart"
    ],
    "ConsoleChartTooltip": [
      "ConsoleChartDetailedTooltip"
    ],
    "ConsoleDialog": [
      "ConsoleProductDialogAction"
    ],
    "ConsoleFinancialProfileCreationFormButtonAction": [
      "ConsoleFinancialProfileCreationFormButtonRedirectAction",
      "ConsoleFinancialProfileCreationFormButtonSelectAction"
    ],
    "ConsoleFormInput": [
      "CheckboxOption",
      "ConsoleArrayInput",
      "ConsoleCheckbox",
      "ConsoleChildrenGroup",
      "ConsoleEmailInput",
      "ConsoleNumberInput",
      "ConsoleRadioButtonInput",
      "ConsoleSelectInput",
      "ConsoleSelectOption",
      "ConsoleTextArea",
      "ConsoleTextInput"
    ],
    "ConsoleHeaderAction": [
      "ConsoleHeaderLinkAction"
    ],
    "ConsoleHeaderButtonAction": [
      "ConsoleEditPartnerButtonAction"
    ],
    "ConsoleHeaderLinkContext": [
      "ConsoleHeaderPartnerSwitchContext"
    ],
    "ConsoleHeaderMenuItem": [
      "ConsoleHeaderButton",
      "ConsoleHeaderMenuLink",
      "ConsoleHeaderModalLink",
      "ConsoleHeaderPicker",
      "ConsoleHeaderSeparator",
      "ConsoleHeaderText",
      "ConsoleHeaderToggleBar",
      "FeedbackButton",
      "NotificationCenter"
    ],
    "ConsoleHeaderPopupButton": [
      "FeedbackButton"
    ],
    "ConsoleHeaderSectionData": [
      "ConsoleHeaderButton",
      "ConsoleHeaderLogo",
      "ConsoleHeaderMenu",
      "ConsoleHeaderMenuLink",
      "ConsoleHeaderModalLink",
      "ConsoleHeaderNavLink",
      "ConsoleHeaderPicker",
      "FeedbackButton",
      "NotificationCenter"
    ],
    "ConsoleHeaderSharedUI": [
      "ConsoleHeaderPicker",
      "NotificationCenter"
    ],
    "ConsoleLinkAction": [
      "ConsoleProductLinkAction"
    ],
    "ConsolePartnerChangeProductsVisibilityResponse": [
      "ConsolePartnerChangeProductsVisibilityErrorResponse",
      "ConsolePartnerChangeProductsVisibilitySuccessResponse"
    ],
    "ConsolePartnerCreationFormButtonAction": [
      "ConsolePartnerCreationFormCancelAction",
      "ConsolePartnerCreationFormNextAction",
      "ConsolePartnerCreationFormPreviousAction",
      "ConsolePartnerCreationFormSubmitAction"
    ],
    "ConsolePartnerDetailsCardHeaderButton": [
      "ConsoleEditPartnerButton"
    ],
    "ConsolePartnerHierarchyMenuCard": [
      "ConsoleHierarchyAddChildPartnerMenuCard",
      "ConsoleHierarchyAddPartnerAccountMenuCard"
    ],
    "ConsolePartnerHierarchyMenuCardAction": [
      "ConsoleHierarchyAddChildPartnerMenuCardAction",
      "ConsoleHierarchyAddPartnerAccountMenuCardAction"
    ],
    "ConsolePartnerHierarchySidebarSectionContent": [
      "ConsolePartnerHierarchySidebarPartnerConfigurationSectionContent"
    ],
    "ConsolePartnerHierarchyTreeCardButtons": [
      "ConsoleHierarchyOpenMenuButton"
    ],
    "ConsolePartnerHierarchyTreeCardButtonsAction": [
      "ConsoleHierarchyOpenMenuButtonAction"
    ],
    "ConsolePartnerScopeSwitchActionDialogButtonAction": [
      "ConsolePartnerScopeSwitchActionDialogCancelButtonAction",
      "ConsolePartnerScopeSwitchActionDialogContinueButtonAction"
    ],
    "ConsoleProductActions": [
      "ConsoleHeaderLinkAction",
      "ConsoleProductDeselectAction",
      "ConsoleProductDialogAction",
      "ConsoleProductDialogCloseAction",
      "ConsoleProductLinkAction",
      "ConsoleProductSelectAction"
    ],
    "ConsoleProductConfigsFormSession": [
      "ConsoleProductConfigsFormSessionDeclarativeForm",
      "ConsoleProductConfigsFormSessionSubApp"
    ],
    "ConsoleProductDetailsPanelAction": [
      "ConsoleProductDeselectTrigger",
      "ConsoleProductSelectProductTrigger"
    ],
    "ConsoleProductDetailsSection": [
      "ConsoleProductDetailsBody",
      "ConsoleProductDetailsCard",
      "ConsoleProductDetailsNavigation"
    ],
    "ConsoleProductSelectionButton": [
      "ConsoleProductSelectionCancelButton",
      "ConsoleProductSelectionSaveButton"
    ],
    "ConsoleProductSelectionButtonTriggers": [
      "ConsoleProductSelectionCancelButtonTrigger",
      "ConsoleProductSelectionDialogButtonCancelTrigger",
      "ConsoleProductSelectionDialogButtonRedirectTrigger",
      "ConsoleProductSelectionSaveButtonTrigger"
    ],
    "ConsoleProductSelectionSessionStepsHeaderButtonAction": [
      "ConsoleProductSelectionSessionStepsHeaderRightButtonTrigger"
    ],
    "ConsoleQueryFilterInputElement": [
      "EGDSBasicSelect",
      "EGDSCheckBoxGroup",
      "EGDSDatePickerInputField",
      "EGDSTextInputField"
    ],
    "ConsoleSwitchAccountAction": [
      "ConsoleSwitchToLiveAccount",
      "ConsoleSwitchToSandboxAccount"
    ],
    "ConsoleTableContent": [
      "ConsoleTableContentLoading",
      "ConsoleTableStackedContent",
      "EGDSIconText",
      "EGDSPlainText",
      "EGDSSpannableText",
      "EGDSStandardBadge",
      "EGDSStylizedText",
      "SubAppCta"
    ],
    "ConsoleTableStackedContentTextType": [
      "EGDSSpannableText",
      "EGDSStylizedText"
    ],
    "ContributorProductsAction": [
      "ContributorProductsBasicAction",
      "ContributorProductsComponentCardAction",
      "ContributorProductsProductCardAction",
      "ContributorProductsSettingAction"
    ],
    "ContributorProductsApiTrafficControl": [
      "ContributorProductsApiTrafficFormControl",
      "ContributorProductsApiTrafficTableControl"
    ],
    "ContributorProductsApiTrafficFormField": [
      "ContributorProductsConsoleTypeahead",
      "ContributorProductsInputSelectPair",
      "EGDSBasicSelect",
      "EGDSTextInputField"
    ],
    "ContributorProductsCard": [
      "ContributorProductsFullWidthCard",
      "ContributorProductsProductCard"
    ],
    "ContributorProductsConsoleQueryFilterInputElement": [
      "EGDSBasicSelect",
      "EGDSTextInputField"
    ],
    "ContributorProductsConsoleTableContent": [
      "ContributorProductsButton",
      "ContributorProductsConsoleTableContentLoading",
      "ContributorProductsConsoleTableStackedContent",
      "ContributrorProductsConsoleContextMenu",
      "EGDSPlainText",
      "EGDSSpannableText",
      "EGDSStandardBadge",
      "EGDSStylizedText"
    ],
    "ContributorProductsConsoleTableStackedContentTextType": [
      "EGDSSpannableText",
      "EGDSStylizedText"
    ],
    "ContributorProductsFormField": [
      "ContributorProductsTextAreaField",
      "EGDSTextInputField"
    ],
    "ContributorProductsLayoutContent": [
      "ContributorProductsSettingList"
    ],
    "ContributorProductsPartnerSideSheetContentItem": [
      "ContributorProductsDescriptionContent",
      "ContributorProductsPartnerProductSelectionRequestCard"
    ],
    "ContributorProductsProductDetailValue": [
      "ContributorProductsTextList",
      "EGDSPlainText",
      "Icon"
    ],
    "ContributorProductsSheetContent": [
      "ContributorProductsPartnerProductSelectionDetailsContent",
      "ContributorProductsProductMigrationConfirmationContent"
    ],
    "ControlPlaneSupportButton": [
      "ControlPlaneSupportPrimaryButton",
      "ControlPlaneSupportSecondaryButton",
      "ControlPlaneSupportUploadButton"
    ],
    "DataPairGraphic": [
      "Icon",
      "Mark"
    ],
    "DataQualityOnboardingInput": [
      "DataQualityBasicSelect",
      "EGDSTextInputField"
    ],
    "DataTableAction": [
      "DataTableActionButton",
      "DataTableActionMenuItem"
    ],
    "DataTableActionElement": [
      "DataTableActionButton",
      "DataTableActionsMenu"
    ],
    "DataTableActionPayload": [
      "DataTableCustomActionPayload",
      "DataTableSnapshotActionPayload"
    ],
    "DataTableCellContent": [
      "DataTableActionButton",
      "DataTableActionsMenu",
      "EGDSStandardLink",
      "EGDSStylizedText",
      "Icon",
      "UIPrimaryButton",
      "UISecondaryButton",
      "UITertiaryButton"
    ],
    "DataTableFilterComponent": [
      "EGDSBasicSelect",
      "EGDSCheckBoxGroup",
      "EGDSDateRangePicker",
      "EGDSTextInputField"
    ],
    "DataTableFiltersSectionDisplay": [
      "EGDSExpandoCard"
    ],
    "DocumentationChatbotResponse": [
      "IntelligentAgentChatbotResponse",
      "NoConfiguration",
      "ZendeskChatbotResponse"
    ],
    "DocumentationDocumentResponse": [
      "DocumentationDocumentErrorResponse",
      "DocumentationDocumentSuccessResponse"
    ],
    "DocumentationHeaderElement": [
      "DocumentationHeaderLogoElement",
      "EGDSStandardLink"
    ],
    "DocumentationNavigationElement": [
      "DocumentationNavigationExpandoElement",
      "DocumentationNavigationLinkElement",
      "EGDSStandardLink"
    ],
    "DocumentationQueryResponse": [
      "DocumentationAppsResponse",
      "DocumentationCustomerSurveyResponse",
      "DocumentationDocumentErrorResponse",
      "DocumentationDocumentSuccessResponse",
      "DocumentationFeedbackResponse",
      "DocumentationHeaderResponse",
      "DocumentationNavigationResponse",
      "DocumentationRelatedSection",
      "DocumentationRelationshipsErrorResponse",
      "DocumentationRelationshipsSuccessResponse",
      "DocumentationSearchResponse",
      "IntelligentAgentChatbotResponse",
      "NoConfiguration",
      "ZendeskChatbotResponse"
    ],
    "DocumentationRelationshipsResponse": [
      "DocumentationDocumentErrorResponse",
      "DocumentationRelationshipsErrorResponse",
      "DocumentationRelationshipsSuccessResponse"
    ],
    "DurationSpan": [
      "DurationSpanSelector"
    ],
    "EGDSBadge": [
      "ConsoleProductStatusBadge",
      "EGConsoleSandboxBadge",
      "EGDSBadgeNotification",
      "EGDSLoyaltyBadge",
      "EGDSProgramBadge",
      "EGDSStandardBadge"
    ],
    "EGDSButton": [
      "EGDSOverlayButton",
      "EGDSSearchFormButton",
      "InsurTechExperienceDialogTrigger",
      "UIFloatingButton",
      "UIPrimaryButton",
      "UISecondaryButton",
      "UITertiaryButton"
    ],
    "EGDSCheckBox": [
      "EGDSBasicCheckBox",
      "FooterComplianceLogoCheckBox"
    ],
    "EGDSComposableTextElement": [
      "EGDSBulletedList",
      "EGDSComposableTextElementSectionContainer",
      "EGDSGraphicText",
      "EGDSHeading",
      "EGDSIconText",
      "EGDSInlineLink",
      "EGDSLinkList",
      "EGDSOrderedList",
      "EGDSParagraph",
      "EGDSPlainText",
      "EGDSSpannableText",
      "EGDSStandardLink",
      "EGDSStylizedText",
      "EGDSTextIconList",
      "EGDSTextListSection",
      "EGDSTextSection",
      "EGDSUnorderedList"
    ],
    "EGDSComposableTextElementContainer": [
      "EGDSComposableTextElementSectionContainer"
    ],
    "EGDSDatePicker": [
      "EGDSDateRangePicker",
      "EGDSSingleDatePicker"
    ],
    "EGDSElement": [
      "AccentColorFormField",
      "ApiCheckbox",
      "ApiClientButton",
      "ApiClientCardOption",
      "ApiClientCheckbox",
      "ApiClientDescriptionTextArea",
      "ApiClientDetailsWarningCheckbox",
      "ApiClientHeader",
      "ApiClientIcon",
      "ApiClientInput",
      "ApiClientLink",
      "ApiClientNameInput",
      "ApiClientParagraph",
      "ApiClientTextArea",
      "DataQualityBasicSelect",
      "DurationSpanSelector",
      "EGDSBadgeNotification",
      "EGDSBasicCheckBox",
      "EGDSBasicLocalizedText",
      "EGDSBasicOption",
      "EGDSBasicSelect",
      "EGDSBasicStepInput",
      "EGDSBasicTab",
      "EGDSBasicTabs",
      "EGDSBasicTravelerSelector",
      "EGDSBulletedList",
      "EGDSCardinalLocalizedText",
      "EGDSComposableTextElementSectionContainer",
      "EGDSCompositeLocalizedText",
      "EGDSEmailInputField",
      "EGDSErrorSummary",
      "EGDSHeading",
      "EGDSImageLink",
      "EGDSImageLinkCard",
      "EGDSInfantSeatRadioButton",
      "EGDSLinkList",
      "EGDSLocalizedTextTemplate",
      "EGDSLoyaltyBadge",
      "EGDSNumberInputField",
      "EGDSOrderedList",
      "EGDSOverlayButton",
      "EGDSParagraph",
      "EGDSPasswordInputField",
      "EGDSProgramBadge",
      "EGDSRoomsTravelerSelector",
      "EGDSSearchFormButton",
      "EGDSSearchFormDatePickerField",
      "EGDSSearchFormInputField",
      "EGDSSearchFormLocationField",
      "EGDSSearchFormLocationsField",
      "EGDSSearchFormTravelersField",
      "EGDSSectionContainer",
      "EGDSSpannableText",
      "EGDSStandardBadge",
      "EGDSStandardLink",
      "EGDSStandardMessagingCard",
      "EGDSTextAreaInputField",
      "EGDSTextIconLinkListItem",
      "EGDSTextIconList",
      "EGDSTextIconListItem",
      "EGDSTextInputField",
      "EGDSTextListSection",
      "EGDSTextSection",
      "EGDSTextStandardListItem",
      "EGDSTextWithMarkListItem",
      "EGDSToast",
      "EGDSTravelerChildAgeSelect",
      "EGDSTravelerInfantSeatSelection",
      "EGDSTravelerStepInput",
      "EGDSTypeaheadInputField",
      "EGDSUnorderedList",
      "Illustration",
      "IncentivesCollectionsListTab",
      "PartnerPaymentsRadioGroup",
      "PartnerPaymentsTextInputField",
      "PrimaryColorFormField",
      "ProductParagraph",
      "SearchFormDurationSpanField",
      "SecondaryColorFormField",
      "UIBanner",
      "UIEmptyState",
      "UIMessagingCard",
      "UIPrimaryButton",
      "UIPrimaryFloatingActionButton",
      "UISecondaryButton",
      "UISecondaryFloatingActionButton",
      "UITertiaryButton"
    ],
    "EGDSElements": [
      "EGDSSectionContainer"
    ],
    "EGDSExpando": [
      "EGDSExpandoCard",
      "EGDSExpandoLink"
    ],
    "EGDSInputField": [
      "ConsoleEmailInput",
      "ConsoleNumberInput",
      "ConsoleTextArea",
      "ConsoleTextInput",
      "EGDSDatePickerInputField",
      "EGDSEmailInputField",
      "EGDSNumberInputField",
      "EGDSPasswordInputField",
      "EGDSSearchFormDatePickerField",
      "EGDSSearchFormInputField",
      "EGDSSearchFormLocationField",
      "EGDSSearchFormTravelersField",
      "EGDSTextAreaInputField",
      "EGDSTextInputField",
      "EGDSTypeaheadInputField",
      "EmailInputField",
      "PartnerPaymentsTextInputField",
      "PartnerPaymentsTypeaheadInputField",
      "SearchFormDurationSpanField"
    ],
    "EGDSInputValidation": [
      "DurationSpanInputValidation",
      "EGDSMaxLengthInputValidation",
      "EGDSMinLengthInputValidation",
      "EGDSRangeInputValidation",
      "EGDSRegexInputValidation",
      "EGDSRequiredInputValidation",
      "EGDSSameValueInputValidation",
      "EGDSTravelersInputValidation",
      "GreaterThanFieldInputValidation",
      "LessThanFieldInputValidation",
      "PartnerPaymentsCheckFields",
      "PartnerPaymentsValidateAge",
      "PaymentAccountRoutingNumberValidation"
    ],
    "EGDSList": [
      "EGDSOrderedList",
      "EGDSUnorderedList"
    ],
    "EGDSLocalizedText": [
      "EGDSBasicLocalizedText",
      "EGDSCardinalLocalizedText",
      "EGDSCompositeLocalizedText"
    ],
    "EGDSMenu": [
      "AuthenticatedUserMenu",
      "DocumentationMenu",
      "EGDSGraphicMenu",
      "EGDSTextMenu"
    ],
    "EGDSMenuListItem": [
      "DocumentationHeaderMenuLinkItem",
      "EGDSMenuListIconTextItem",
      "EGDSMenuStandardItem",
      "EGDSMenuTextItem",
      "LanguageSelectorMenuItem"
    ],
    "EGDSNestedLevelOneListItem": [
      "EGDSNestedLevelOneIconListItem",
      "EGDSNestedLevelOneStandardListItem"
    ],
    "EGDSOption": [
      "BillingProfileOption",
      "ConsoleRadioOption",
      "ConsoleSelectOption",
      "EGDSBasicOption",
      "PaymentProfileOption",
      "PaymentProfileTypeOption"
    ],
    "EGDSPill": [
      "EGDSBasicPill"
    ],
    "EGDSRadioButton": [
      "EGDSBasicRadioButton",
      "EGDSInfantSeatRadioButton",
      "PaymentMethodOption",
      "SiteBuilderLogoRadioButton"
    ],
    "EGDSRadioGroup": [
      "EGDSBasicRadioGroup",
      "EGDSTravelerInfantSeatSelection",
      "PartnerPaymentsRadioGroup",
      "SiteBuilderLogoRadioGroup"
    ],
    "EGDSSearchFormAction": [
      "EGDSOpenDatePickerAction",
      "EGDSOpenTravelerSelectorAction",
      "EGDSSearchFormSelectAction",
      "EGDSShowSearchFormAction",
      "EGDSSubmitSearchFormAction",
      "OpenDurationSpanAction",
      "OpenTypeaheadAction"
    ],
    "EGDSSearchFormField": [
      "EGDSSearchFormDatePickerField",
      "EGDSSearchFormLocationField",
      "EGDSSearchFormTravelersField",
      "SearchFormDurationSpanField"
    ],
    "EGDSSearchInputs": [],
    "EGDSSectionHeading": [
      "ConsoleConfigHeading",
      "ConsoleProductSectionHeading",
      "ConsoleWelcomeCardSectionHeading",
      "EGDSBasicSectionHeading",
      "SampleSectionHeading"
    ],
    "EGDSSelect": [
      "ConsoleSelectInput",
      "DataQualityBasicSelect",
      "EGDSBasicSelect",
      "EGDSTravelerChildAgeSelect"
    ],
    "EGDSSpannableTextItem": [
      "EGDSGraphicText",
      "EGDSHeading",
      "EGDSInlineLink",
      "EGDSPlainText",
      "EGDSStandardLink",
      "EGDSStylizedText"
    ],
    "EGDSStepInput": [
      "EGDSBasicStepInput",
      "EGDSTravelerStepInput"
    ],
    "EGDSSubMenu": [
      "DocumentationHeaderSubMenu",
      "EGDSStandardSubMenu"
    ],
    "EGDSTab": [
      "EGDSBasicTab",
      "IncentivesCollectionsListTab"
    ],
    "EGDSTabs": [
      "EGDSBasicTabs",
      "IncentivesCollectionsListTabs"
    ],
    "EGDSTemplateModels": [
      "EGDSBasicLocalizedText",
      "EGDSCardinalLocalizedText"
    ],
    "EGDSText": [
      "DataQualityOnboardingAction",
      "EGDSBasicLocalizedText",
      "EGDSCardinalLocalizedText",
      "EGDSCompositeLocalizedText",
      "EGDSGraphicText",
      "EGDSHeading",
      "EGDSIconText",
      "EGDSInlineLink",
      "EGDSLocalizedTextTemplate",
      "EGDSParagraph",
      "EGDSPlainText",
      "EGDSSpannableText",
      "EGDSStandardLink",
      "EGDSStylizedText",
      "EGDSTextIconLinkListItem",
      "EGDSTextIconListItem",
      "EGDSTextStandardListItem",
      "EGDSTextWithMarkListItem",
      "ProductEGDSError"
    ],
    "EGDSTextContainer": [
      "EGDSTextSection"
    ],
    "EGDSTextList": [
      "EGDSBulletedList",
      "EGDSOrderedList",
      "EGDSTextIconList",
      "EGDSUnorderedList"
    ],
    "EGDSTextListContainer": [
      "EGDSTextListSection"
    ],
    "EGDSTextListItem": [
      "EGDSTextIconLinkListItem",
      "EGDSTextIconListItem",
      "EGDSTextStandardListItem",
      "EGDSTextWithMarkListItem",
      "EarnMessageContainerListItem"
    ],
    "EGDSTraveler": [
      "EGDSBasicTraveler",
      "EGDSTravelerChildren",
      "EGDSTravelerInfant"
    ],
    "EGDSTravelerSelector": [
      "EGDSBasicTravelerSelector",
      "EGDSRoomsTravelerSelector"
    ],
    "EntityDetailContextInput": [
      "EntityDetailContextAttachmentInput",
      "EntityDetailContextSummaryInput"
    ],
    "EntityDetailHeadingActionButton": [
      "UIPrimaryButton",
      "UISecondaryButton"
    ],
    "EntityDetailSection": [
      "EntityDetailDataSection",
      "EntityDetailTableSection"
    ],
    "HeaderFormAction": [
      "AddLinkAction",
      "RemoveLinkAction"
    ],
    "IError": [
      "BadRequest",
      "EntityNotFound",
      "InternalServerError",
      "PermissionDenied",
      "RemoteServiceError",
      "Unauthorized"
    ],
    "IncentivesCollectionFormResponse": [
      "EGDSErrorSummary",
      "IncentivesCollectionForm"
    ],
    "IncentivesCollectionMutationResponse": [
      "EGDSErrorSummary",
      "IncentivesCollectionMutationSuccess"
    ],
    "IncentivesCollectionsBrowseResponse": [
      "EGDSErrorSummary",
      "IncentivesCollectionsBrowse"
    ],
    "InsurTechDialogTriggerAction": [
      "InsurTechDialogTriggerClickAction"
    ],
    "InventoryManagementCardLinkActions": [
      "SiteBuilderOpenLodgingBusinessModelsForm"
    ],
    "KycFormResponse": [
      "KycForm",
      "KycFormError"
    ],
    "KycReviewResponse": [
      "KycFormError",
      "KycReview"
    ],
    "KycSubmitResponse": [
      "KycFormError",
      "KycSubmitSuccess"
    ],
    "LookupItem": [
      "LookupSimpleItem"
    ],
    "PartnerPaymentFieldType": [
      "EGDSBasicSelect",
      "EGDSStylizedText",
      "Image",
      "PartnerPaymentsRadioGroup",
      "PartnerPaymentsStylizedText",
      "PartnerPaymentsTextInputField",
      "PartnerPaymentsTypeaheadInputField"
    ],
    "PartnerPaymentsBaseError": [
      "PartnerPaymentsValidationError"
    ],
    "PartnerPaymentsButton": [
      "UIPrimaryButton",
      "UISecondaryButton"
    ],
    "PaymentAccountResponseData": [
      "PartnerPaymentsErrorList",
      "PaymentAccountResponse"
    ],
    "RecommendationAddStrategyProvisionResponse": [
      "RecommendationAddStrategyProvision",
      "RecommendationsError"
    ],
    "RecommendationProvisionConfirmationResponse": [
      "RecommendationProvisionConfirmation",
      "RecommendationsError"
    ],
    "RecommendationsAddPromptResponse": [
      "RecommendationsAddPromptStatus",
      "RecommendationsError"
    ],
    "RecommendationsCarouselResponse": [
      "RecommendationsCarousel",
      "RecommendationsError"
    ],
    "RecommendationsGetPlacementGroupResponse": [
      "GetPlacementGroup",
      "RecommendationsError"
    ],
    "RecommendationsGetPlacementResponse": [
      "GetPlacement",
      "RecommendationsError"
    ],
    "RecommendationsPlacementGroupResponse": [
      "PlacementGroupType",
      "RecommendationsError"
    ],
    "RecommendationsPlacementGroupsResponse": [
      "GetPlacementGroups",
      "RecommendationsError"
    ],
    "RecommendationsPlacementResponse": [
      "PlacementResponse",
      "RecommendationsError"
    ],
    "RecommendationsSimulationFormInput": [
      "EGDSBasicSelect",
      "EGDSDatePickerInputField",
      "EGDSTextInputField",
      "ExploreBasicCheckbox"
    ],
    "SiteBuilderAction": [
      "EnableLineOfBusinessAction",
      "EnableTravelAdsAction"
    ],
    "SiteBuilderField": [
      "FooterComplianceLogos",
      "SiteBuilderColorField",
      "SiteBuilderImageField",
      "SiteBuilderLogoRadioField",
      "SiteBuilderSelectField",
      "SiteBuilderStandardSwitchField",
      "SiteBuilderTextAreaField",
      "SiteBuilderTextField",
      "SiteBuilderTypeaheadField"
    ],
    "SiteBuilderFormDialog": [
      "AuthRealmErrorDialog",
      "AuthRealmSuccessDialog",
      "FQDNErrorDialog",
      "FQDNSuccessDialog",
      "TravelAdsConfirmation"
    ],
    "SiteBuilderFormDialogAction": [
      "ConfirmAction",
      "DismissAction"
    ],
    "SubAppPageHeading": [
      "AiWorkbenchApplicationRegistrationHeading",
      "AiWorkbenchModelLifecycleHeading",
      "AiWorkbenchModelRegistrationHeading",
      "AiWorkbenchPlatformUtilizationHeading",
      "CollectionsPageHeading",
      "DTM_MainPageHeading"
    ],
    "UIAction": [
      "ConsoleFormCancelAction",
      "ConsoleFormNextAction",
      "ConsoleFormPreviousAction",
      "ConsoleFormSubmitAction",
      "EGDSOpenDatePickerAction",
      "EGDSOpenTravelerSelectorAction",
      "EGDSSubmitSearchFormAction",
      "OpenTypeaheadAction",
      "ShoppingSortAndFilterAction",
      "UILinkAction"
    ],
    "UIFloatingActionButton": [
      "UIPrimaryFloatingActionButton",
      "UISecondaryFloatingActionButton"
    ],
    "UIGraphic": [
      "Icon",
      "Illustration",
      "Mark"
    ],
    "UIToggle": [
      "CheckboxOption",
      "ConsoleToggle",
      "EGDSStandardSwitch"
    ],
    "URI": [
      "BasicURI",
      "GeoURI",
      "HttpURI",
      "MailToURI",
      "SMSToURI",
      "SelfReferencedURI",
      "TelURI"
    ],
    "UpdateChargebackRecommendationResponse": [
      "UpdateChargebackRecommendationErrorResponse",
      "UpdateChargebackRecommendationSuccessResponse"
    ],
    "UserInviteFormResponse": [
      "UserInviteForm",
      "UserManagementDomainAccountBanner",
      "UserManagementError"
    ],
    "UserRolesFormResponse": [
      "PendingInviteForm",
      "PendingInviteSheet",
      "UserManagementDomainAccountBanner",
      "UserManagementError",
      "UserRolesForm"
    ],
    "UsersTableHeaderResponse": [
      "UserManagementError",
      "UsersTableHeader"
    ],
    "UsersTableResponse": [
      "DataTable",
      "UserManagementError"
    ],
    "Visible": [
      "FooterLogoConfiguration",
      "LinkConfiguration",
      "MenuConfiguration",
      "SiteBuilderImageField",
      "SiteBuilderLogoRadioField",
      "SiteBuilderSelectField",
      "SiteBuilderStandardSwitchField",
      "SiteBuilderTextAreaField",
      "SiteBuilderTextField",
      "SiteBuilderTypeaheadField"
    ],
    "paymentAccountUpdateResponseData": [
      "PartnerPaymentsErrorList",
      "PaymentAccount"
    ],
    "paymentProfileCreateResponseData": [
      "PartnerPaymentsErrorList",
      "PaymentProfileData"
    ],
    "paymentProfileUpdateResponseData": [
      "PartnerPaymentsErrorList",
      "PaymentProfileData"
    ]
  }
}
    
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'bernie-plugin-mobx';

class ScrimStore extends Store {
  @observable public showScrim = false;

  constructor(logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    this.showScrim = false;
    makeObservable(this);
  }

  @action
  public setScrimState(currentScrimState: boolean): void {
    this.showScrim = currentScrimState;
  }

  hydrate(): void {
    this.showScrim = false;
  }
}

export default ScrimStore;

import { codeSplit } from 'bernie-core';
import { DefaultController } from './default-controller';
import { EgPagePath } from '../common/utils/eg-page-path';

export class ProductDetailsController extends DefaultController {
  public pageTitle = 'Product Details';

  public pageId = 'product-details';

  public path = EgPagePath.PRODUCT_DETAILS;

  public routeName = 'product-details';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(
    () => import(/* webpackChunkName: "ProductDetails" */ '../views/product-details-page/product-details-page')
  );

  public exact = true;

  public isSandboxEnabled = true;
}

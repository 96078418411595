import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class ProductCatalogController extends DefaultController {
  public pageTitle = 'Product Catalog';

  public pageId = 'AllProducts';

  public path = EgPagePath.PRODUCTS;

  public routeName = 'All products';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(
    () => import(/* webpackChunkName: "ProductCatalog" */ '../views/product-catalog-page/product-catalog-page')
  );

  public exact = true;

  public isSandboxEnabled = true;
}

import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class WelcomePageController extends DefaultController {
  public pageTitle = 'Welcome';

  public pageId = 'welcomePage';

  public path = EgPagePath.WELCOME;

  public routeName = 'WelcomePage';

  public isPartnerProtected = false;

  public isAuthenticated = false;

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(() => import('../views/welcome-page/welcome-page'));

  public exact = true;
}

import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class PartnerSignUpController extends DefaultController {
  public pageTitle = 'Partner Sign-up';

  public pageId = 'PartnerSignUp';

  public path = EgPagePath.PARTNER_SIGN_UP;

  public routeName = 'PartnerSignUp';

  public bundles = [];

  public isPartnerProtected = false;

  public isAuthenticated = false;

  public component = codeSplit(
    () => import(/* webpackChunkName: "PartnerSignUp" */ '../views/partner-sign-up-page/partner-sign-up-page')
  );

  public exact = true;
}

import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { Server } from '@hapi/hapi';
import { EgConsoleError } from 'common/logging/console-errors';
import { Logger } from '@shared-ui/logger-context/dist/commonjs/components/logger';
import { EVENT_NAME_NOT_PROPERLY_FORMATTED, NOT_IMPLEMENTED } from './sub-app-client-logger';
import { getTags } from './tag-util';
import { LogDataObject } from './server-logger-utils';
import UserContextSingleton from './global-logging-context-singleton';

const LOGGER_IDENTIFIER = 'eg-console.server';
const NOT_UPPER_CASE_AND_UNDERSCORES_REGEX = new RegExp('[^A-Z_]+');

export type LogFunction = Server['log'];

export const ServerLogger = (log: LogFunction): Logger => {
  const validateEventName = (name: string): void => {
    if (NOT_UPPER_CASE_AND_UNDERSCORES_REGEX.test(name)) {
      const data = {
        systemEvent: new SystemEvent(SystemEventLevel.ERROR, EVENT_NAME_NOT_PROPERLY_FORMATTED),
        loggerId: LOGGER_IDENTIFIER,
        invalidEventName: name,
      };
      log(getTags(data), data);
    }
  };

  const handleLogging = (eventName: string, level: SystemEventLevel, ...args: LogDataObject[]): void => {
    let argsData = {};
    if (args) {
      [argsData] = args;
    }

    let context = {};
    if (UserContextSingleton) {
      context = {
        ...UserContextSingleton,
      };
    }

    const data = {
      systemEvent: new SystemEvent(level, eventName),
      loggerId: LOGGER_IDENTIFIER,
      ...context,
      ...argsData,
    };

    log(getTags(data), data);

    validateEventName(eventName);
  };

  return {
    event(): void {
      throw new EgConsoleError(NOT_IMPLEMENTED);
    },
    error(eventName: string, ...args): void {
      handleLogging(eventName, SystemEventLevel.ERROR, ...args);
    },
    info(eventName: string, ...args): void {
      handleLogging(eventName, SystemEventLevel.INFO, ...args);
    },
    warning(eventName: string, ...args): void {
      handleLogging(eventName, SystemEventLevel.WARN, ...args);
    },
  };
};

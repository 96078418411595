import { SerializedData } from 'bernie-core';
import { Request } from 'bernie-http';
import { Logger, NOOP_LOGGER, SystemEventLevel } from 'bernie-logger';
import { serializeError } from 'serialize-error';
import { EmailAddress, Name } from 'common/types/console-types';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'bernie-plugin-mobx';
import { fetchPrincipalToken } from '../common/utils/eg-token-utils';
import { TRACE_ID } from '../constants';
import { PrincipalUserData } from './model/identity-authorization-models';
import { Config } from './config-store';

export class UserStore extends Store {
  @observable public name!: Name;

  @observable public primaryEmail!: EmailAddress;

  @observable public scopes: string | undefined = undefined;

  @observable public partnerAccountId: string | undefined = undefined;

  @observable public userId: string | undefined = undefined;

  @observable public actingUserId: string | undefined = undefined;

  @observable public sessionId: string | undefined = undefined;

  appConfig?: Config;

  constructor(logger: Logger = NOOP_LOGGER, appConfig?: Config) {
    super({}, logger);
    this.appConfig = appConfig;
    makeObservable(this);
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
    this.appConfig = data.appConfig;
  }

  @action
  public setActiveUser = async ({
    request,
    principalToken,
  }: {
    request: Request;
    principalToken: string;
  }): Promise<void> => {
    try {
      this.setUserContextData(principalToken);
    } catch (e) {
      const traceId = request.headers[TRACE_ID];
      request.log(['eg-console.error.active-user', traceId], {
        level: SystemEventLevel.CRITICAL,
        error: serializeError(e),
      });
    }
  };

  public fetchPrincipalToken = async (request: Request): Promise<string | null> => {
    if (!this.appConfig) {
      return null;
    }
    const { userTokenUrl } = this.appConfig;
    return fetchPrincipalToken(request, userTokenUrl);
  };

  getPrincipalUserData = (principalToken: string): PrincipalUserData => {
    const principalUser = Buffer.from(principalToken.split('.')[1], 'base64').toString('utf-8');
    return JSON.parse(principalUser);
  };

  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    const json = super.toJSON(ignoreAdditionalKeys);
    delete json.appConfig?.secret;
    delete json.appConfig?.credential;
    return json;
  }

  private setUserContextData(principalToken: string): void {
    const principalUser = this.getPrincipalUserData(principalToken);

    this.scopes = principalUser.scope;
    this.partnerAccountId = principalUser.partner_account_id;
    this.userId = principalUser.sub;
    this.actingUserId = principalUser.actor_id;
    this.sessionId = principalUser.jti;

    // TODO
    // Object.assign(UserContextSingleton, this.activeUser);
  }
}

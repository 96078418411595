import { CodeSplit, Controller, FetchOptions } from 'bernie-core';
import { HttpStatus, PageData } from 'bernie-http';
import { serializeError } from 'serialize-error';
import { SystemEventLevel } from 'bernie-logger';
import { BannerStore } from 'stores/banner-store';
import { UserStore } from 'stores/user-store';
import { PartnerStore } from 'stores/partner-store';
import { PlatformContextStore } from 'stores/platform-context-store';
import NavigationStore from '../stores/navigation-store';
import { TRACE_ID } from '../constants';

export abstract class DefaultController implements Controller {
  public abstract pageTitle: string;

  public abstract bundles: string[];

  public abstract component: CodeSplit;

  public abstract exact: boolean;

  public abstract pageId: string;

  public abstract path: string;

  public abstract routeName: string;

  public isPartnerProtected = true;

  public isAuthenticated = true;

  public isSandboxEnabled = false;

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({
      title: this.getPageTitle(),
      pageId: this.pageId,
      pageName: this.pageId,
    }) as Promise<PageData>;
  }

  public async fetch(options: FetchOptions | undefined) {
    if (typeof options === 'object' && Object.keys(options).length > 0) {
      const { stores, isServer } = options;
      if (isServer) {
        const userStore: UserStore = stores?.get<UserStore>('user');
        const partnerStore: PartnerStore = stores?.get<PartnerStore>('partner');
        const platformContextStore: PlatformContextStore = stores?.get<PlatformContextStore>('platformContext');
        let userJwt = null;
        try {
          userJwt = await userStore.fetchPrincipalToken(options.request);
        } catch (error) {
          const traceId = options.request?.headers[TRACE_ID];
          options.request?.log(['DEFAULT_CONTROLLER_STORE_INIT_ERROR', 'eg-console.error.active-user', traceId], {
            level: SystemEventLevel.CRITICAL,
            error: serializeError(error),
          });
        }
        const storePromises: Promise<unknown>[] = [];
        if (userJwt) {
          storePromises.push(
            userStore
              .setActiveUser({ request: options.request, principalToken: userJwt })
              .then(() => Promise.resolve({ status: HttpStatus.OK }))
          );
          if (this.isPartnerProtected) {
            const tokenData = userStore.getPrincipalUserData(userJwt);
            partnerStore.setPartnerAccountId(tokenData.partner_account_id);
          }
        }
        await platformContextStore.setContext(options.request);
        await Promise.all(storePromises);
      } else {
        document.title = this.getPageTitle();
        const navigationStore: NavigationStore = stores.get<NavigationStore>('navigation');
        const bannerStore: BannerStore = stores.get<BannerStore>('bannerStore');

        const { banner, bannerLocation } = bannerStore;

        navigationStore.setCurrentPageId(this.pageId);

        if (banner && !bannerLocation) {
          bannerStore.setBannerLocation(options.request.url);
        } else if (banner && bannerLocation && options.request.url !== bannerLocation) {
          bannerStore.clearBanner();
        }

        return Promise.resolve({ status: HttpStatus.OK });
      }
    }
    return Promise.resolve({ status: HttpStatus.OK });
  }

  private getPageTitle(): string {
    if (this.pageTitle) {
      return `${this.pageTitle} - Expedia Group Console`;
    }

    return 'Expedia Group Console';
  }
}

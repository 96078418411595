import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class AccountSelectionController extends DefaultController {
  public pageTitle = 'Account Selection';

  public pageId = 'AccountSelection';

  public path = EgPagePath.CHOOSE_ACCOUNT;

  public routeName = 'AccountSelection';

  public bundles = [];

  public isPartnerProtected = false;

  public component = codeSplit(
    () => import(/* webpackChunkName: "AccountSelection" */ '../views/account-selection-page/account-selection-page')
  );

  public exact = true;
}

import { SystemEvent } from 'bernie-logger';

const LEVEL_UNDEFINED = 'level-undefined';
const EVENT_UNDEFINED = 'event-name-undefined';
const LOGGER_ID_UNDEFINED = 'logger-id-undefined';

export const getEventTag = (eventData: Record<string, unknown>): string => {
  const { systemEvent, loggerId } = eventData;
  let tag = '';

  if (loggerId) {
    tag += `${loggerId}`;
  } else {
    tag += LOGGER_ID_UNDEFINED;
  }

  if (systemEvent) {
    const { name, level } = systemEvent as SystemEvent;
    tag += `.${level.toLowerCase()}.${name.toLowerCase().replace('_', '-')}`;
  } else {
    tag += `.${LEVEL_UNDEFINED}.${EVENT_UNDEFINED}`;
  }

  return tag;
};

export const getTraceIdTag = (eventData: Record<string, unknown>): string => {
  const { traceId } = eventData;
  let tag = '';

  if (traceId) {
    tag += traceId;
  }

  return tag;
};

export const getTags = (eventData: Record<string, unknown>): string[] => {
  const tags = [];
  const traceIdTag = getTraceIdTag(eventData);
  const eventTag = getEventTag(eventData);

  tags.push(eventTag);

  if (traceIdTag) {
    tags.push(traceIdTag);
  }

  return tags;
};

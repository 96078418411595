import { ClientFactories, Store } from 'bernie-plugin-mobx';
import { ClientStoreOptions } from 'bernie-plugin-mobx/dist/spi/client-factories';

/* eslint-disable no-useless-constructor, @typescript-eslint/ban-types */

type StoreFactory<S extends Store> = (options: ClientStoreOptions) => S;

/**
 * Class used to keep track of when client-side mobx stores are created/hydrated by bernie. See client.ts for an
 * example of how this class is used.
 *
 * @template STORES A type describing the mobx stores managed by this object. The keys are store names and values are
 * store types (e.g., { storeA: StoreA, storeB: StoreB })
 */
export default class ClientStoreManagement<STORES> {
  /**
   * Note: This should only be used internally. To create a new {@link ClientStoreManagement}, use {@link #create}.
   *
   * @param hydratedStoresPromise A promise that resolves to an object of type {@code STORES} when all stores managed
   * by this object have been created and hydrated by bernie.
   * @param clientFactories An object containing factories for all the stores managed by this object. Keys are store
   * names and values are store factories. This is the object that needs to be exported as {@code stores} in client.ts.
   */
  constructor(readonly hydratedStoresPromise: Promise<STORES>, readonly clientFactories: ClientFactories = {}) {}

  /**
   * Helper used to create an empty {@link ClientStoreManagement}. This exists because the constructor can't have a
   * default argument for hydratedStoresPromise and also be type safe.
   */
  static create(): ClientStoreManagement<{}> {
    return new ClientStoreManagement(Promise.resolve({}));
  }

  /**
   * Register a store to be managed.
   *
   * @param name The store name.
   * @param factory A factory that creates an instance of the store.
   * @template N A string literal for the store name (e.g., 'storeA'). Used as a key in the {@code STORES} type for the
   * result of this function.
   * @template S The type of the store being managed. Used as the value at key {@code N} in the {@code STORES} type for
   * the result of this function.
   * @returns A new {@link ClientStoreManagement}. The current instance isn't modified, so make sure to reference the
   * result of this function and not the object where it was called.
   */
  withStore<N extends string, S extends Store>(
    name: N,
    factory: StoreFactory<S>
  ): ClientStoreManagement<STORES & Record<N, S>> {
    // Create a promise that will resolve when the store is created and hydrated, then wrap the factory so it creates
    // a store with a monkey-patched hydrate function that resolves the promise after hydration.
    const storePromise = new Promise<S>((resolve) => {
      this.clientFactories[name] = (options) => {
        const store = factory(options);
        const wrappedHydrate = store.hydrate.bind(store);
        store.hydrate = (data) => {
          wrappedHydrate(data);
          resolve(store);
        };
        return store;
      };
    });

    // Create a promise that resolves to all the stores already managed (this.hydratedStoresPromise) as well as the
    // store being added in this function (storePromise).
    const storesPromise = Promise.all([this.hydratedStoresPromise, storePromise]).then(
      ([stores, store]) => ({ ...stores, [name]: store } as STORES & Record<N, S>)
    );

    return new ClientStoreManagement(storesPromise, this.clientFactories);
  }
}

import { codeSplit } from 'bernie-core';
import { DefaultController } from './default-controller';
import { EgPagePath } from '../common/utils/eg-page-path';

export class ProductConfigsController extends DefaultController {
  public pageTitle = 'Product Management';

  public pageId = 'ProductConfigs';

  public path = EgPagePath.PRODUCT_CONFIGS;

  public routeName = 'ProductConfig';

  public bundles = [];

  public component = codeSplit(
    () => import(/* webpackChunkName: "ProductConfigs" */ '../views/product-configs-page/product-configs-page')
  );

  public exact = true;

  public isSandboxEnabled = true;
}

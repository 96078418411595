import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { computed, makeObservable, observable } from 'mobx';
import { Store } from 'bernie-plugin-mobx';
import { Env } from '@openworld-ui/sub-app-integration-types';

export interface Config {
  environment: Env;
  appTokenUrl: string;
  credential: string;
  secret: string;
  subAppTesting?: boolean;
  userTokenUrl: string;
  accountSignupUrl?: string;
  googleApiKey?: string;
  cookieBannerScriptUrl?: string;
  devHubUrl?: string;
  feedbackSurveyUrl?: string;
  accountLogoutUrl: string;
}

export class ConfigStore extends Store {
  @observable
  appConfig?: Config;

  constructor(logger: Logger = NOOP_LOGGER, appConfig?: Config) {
    super({}, logger);
    this.appConfig = appConfig;
    makeObservable(this);
  }

  @computed
  get subAppTesting(): boolean {
    return this.appConfig?.subAppTesting ?? false;
  }

  hydrate(data: SerializedData): void {
    this.appConfig = data.appConfig;
  }

  public getAppConfig() {
    if (!this.appConfig) {
      throw new Error('No App Config');
    }
    return this.appConfig;
  }

  public toJSON(ignoreAdditionalKeys: string[] | string = []): SerializedData {
    const json = super.toJSON(ignoreAdditionalKeys);
    delete json.appConfig?.secret;
    delete json.appConfig?.credential;
    return json;
  }
}

export const loadBrandData = async brand => {
    
    const brandHash = (brand: string) => {
    if (!brand) {
      return '';
    }
  
    const code = Array.from(brand)
      .map((c) => c.charCodeAt(0))
      .join('');

    return "brand-" + code;
  };

    const brandMap = {
      "brand-1011039599111110115111108101": async () => await import(/* webpackChunkName: "brand-1011039599111110115111108101" */ "./eg_console"),"brand-1011039511297114116110101114": async () => await import(/* webpackChunkName: "brand-1011039511297114116110101114" */ "./eg_partner")
    };
    const brandIndex = brandHash(brand);

    if (brandIndex in brandMap) {
      return await brandMap[brandIndex]();
    } else {
      return await brandMap['brand-1011039599111110115111108101']();
    }
  };
  
// both node and hapi convert all headers to lowercase, based on the
// the http spec, "[header] Field names are case-insensitive"
// https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html
export const TRACE_ID = 'trace-id';
export const AUTHORIZATION = 'Authorization';
export const AUTHORIZATION_HEADER_PREFIX = 'EGToken Principal-JWT=';
export const CONTEXT_PARTNER_ROOT = 'context-partner-root';
export const ADDRESS_FIELD = 'addressLine1';
export const NAV_MIN_WINDOW_WIDTH = 1440;

export enum PaymentPartnerType {
  ACCOUNT_ID = 'ACCOUNT_ID',
  PARTNER_ID = 'PARTNER_ID',
}

export enum ProductSupport {
  FORM = 'form',
  CONFIRMATION = 'confirmation',
}

import { codeSplit } from 'bernie-core';
import { DefaultController } from './default-controller';

export class SubAppController extends DefaultController {
  public pageTitle = '';

  public pageId = 'SubAppPage';

  public path = '/apps/:subdirectory*';

  public routeName = 'SubAppPage';

  public bundles = [];

  // @ts-ignore Unsure how to handle codeSplit
  public component = codeSplit(() => import(/* webpackChunkName: "SubAppPage" */ '../views/sub-app-page/sub-app-page'));

  public exact = true;
}

import { codeSplit } from 'bernie-core';
import { DefaultController } from '../default-controller';

export class CookiePolicyController extends DefaultController {
  public pageTitle = 'Cookie Statement';

  public pageId = 'Cookie Statement';

  public path = '/cookie-statement';

  public routeName = 'CookieStatement';

  public bundles = [];

  public isPartnerProtected = false;

  public isAuthenticated = false;

  public component = codeSplit(
    () => import(/* webpackChunkName: "CookiePolicy" */ '../../views/legal/cookie-policy-page')
  );

  public exact = true;
}

import { SerializedData } from 'bernie-core';
import { Store } from 'bernie-plugin-mobx';
import { ContextInput, getBexApiContext } from 'bernie-context';
import { Request } from 'bernie-http';
import { Logger, NOOP_LOGGER } from 'bernie-logger';

import { action, makeObservable, observable } from 'mobx';
import { PlatformContext } from '@openworld-ui/sub-app-integration-types';

export class PlatformContextStore extends Store implements PlatformContext {
  @observable
  public context!: ContextInput;

  constructor(logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    makeObservable(this);
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
  }

  @action
  public setContext = async (request: Request): Promise<void> => {
    this.context = getBexApiContext(request.context);
  };
}

import { codeSplit, StoreContainer } from 'bernie-core';
import { Request } from 'bernie-http';
import { EgPagePath } from '../common/utils/eg-page-path';
import { ConfigStore } from '../stores/config-store';
import { DefaultController } from './default-controller';
import NotFoundError from './error/not-found-error';

const PAGE_ID = 'TestSubApps';

export class TestSubAppsController extends DefaultController {
  public pageTitle = 'Sub-app Testing';

  public pageId = PAGE_ID;

  public path = EgPagePath.TEST_SUB_APPS;

  public routeName = PAGE_ID;

  public bundles = [];

  public component = codeSplit(
    () => import(/* webpackChunkName: "TestSubApps" */ '../views/test-sub-apps-page/test-sub-apps-page')
  );

  public exact = true;

  shouldStreamResponse(request: Request, stores: StoreContainer): boolean {
    const configStore = stores.get<ConfigStore>('config');
    if (!configStore.subAppTesting) {
      // This feels hacky, but it doesn't seem like there's any other way to disable a view based on config.
      throw new NotFoundError();
    }
    return false;
  }
}

import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class UserManagementController extends DefaultController {
  public pageTitle = 'User Management';

  public pageId = 'userManagementPage';

  public path = EgPagePath.USER_MANAGEMENT;

  public routeName = 'UserManagementPage';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(() => import('../views/user-management/user-management'));

  public exact = true;
}

// This plugin was added because console needs to wrap sub apps in an Experiment Provider so they
// can use the shared ui react components and hooks but the actual IExperiment object and and the
// react context used by the shared ui library are hidden.

import * as React from 'react';
import { BernieBrowserPlugin } from 'bernie-plugins';
import { ExperimentProvider, IExperiment } from '@shared-ui/experiment-context';

export const ExperimentContext = React.createContext({} as IExperiment);

export default function ExperimentStorePlugin(): BernieBrowserPlugin {
  return {
    name: 'console-experiment-store',

    onBrowserWrapRootElement(context, next) {
      const { getAppData, setRootElement } = context;
      const appData = getAppData();
      const bernieExperimentStore = appData.experimentContextStore;

      // This egtnlStore actually gives access to the egtnl evaluator and all exposures, not
      // just the IExperiment
      const egtnlStore = bernieExperimentStore.delegates[1];

      setRootElement(
        (RootElement) =>
          function wrap(props) {
            return (
              <ExperimentContext.Provider value={egtnlStore}>
                <ExperimentProvider config={egtnlStore}>
                  <RootElement>{props.children}</RootElement>
                </ExperimentProvider>
              </ExperimentContext.Provider>
            );
          }
      );

      return next(context);
    },
  };
}

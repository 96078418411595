import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class PartnerHierarchyController extends DefaultController {
  public pageTitle = 'Partner Hierarchy';

  public pageId = 'PartnerHierarchy';

  public path = EgPagePath.HIERARCHY;

  public routeName = 'PartnerHierarchy';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(
    () => import(/* webpackChunkName: "PartnerHierarchy" */ '../views/partner-hierarchy-page/partner-hierarchy-page')
  );

  public exact = true;
}

import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { makeObservable, action, observable } from 'mobx';
import { NavItem } from '@openworld-ui/sub-app-integration-types';
import { Store } from 'bernie-plugin-mobx';

export const HeaderType = {
  DEFAULT: 'DEFAULT',
  MIN: 'MIN',
};

export const LogoColor = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export type HeaderType = typeof HeaderType[keyof typeof HeaderType];
export type LogoColor = typeof LogoColor[keyof typeof LogoColor];

export default class NavigationStore extends Store {
  @observable public headerType: HeaderType;

  @observable public logoColor: LogoColor;

  @observable public currentPageId: string | undefined = undefined;

  @observable public navItems: NavItem[];

  constructor(logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    this.headerType = HeaderType.DEFAULT;
    this.logoColor = LogoColor.DARK;
    this.navItems = [];
    makeObservable(this);
  }

  hydrate(): void {
    this.headerType = HeaderType.DEFAULT;
    this.logoColor = LogoColor.DARK;
  }

  @action
  setHeaderType(value: HeaderType): void {
    this.headerType = value;
  }

  @action
  setLogoColor(value: LogoColor): void {
    this.logoColor = value;
  }

  @action
  setCurrentPageId(value: string): void {
    this.currentPageId = value;
  }

  @action
  setNavItems(value: NavItem[]): void {
    this.navItems = value;
  }
}

import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, makeObservable, observable } from 'mobx';
import { Store } from 'bernie-plugin-mobx';

class SubAppErrorStore extends Store {
  @observable public errorState: Error | undefined = undefined;

  constructor(logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    makeObservable(this);
  }

  @action
  public addErrorState(currentErrorState: Error): void {
    this.errorState = currentErrorState;
  }

  hydrate(): void {
    // this store doesn't depend on any state from the server
  }
}

export default SubAppErrorStore;

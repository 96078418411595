export const EgPagePath = {
  API_CLIENT_CREATE: '/apps/api-clients/create',
  API_CLIENT: '/apps/api-clients',
  AUTHORIZE: '/eg/auth/custom/authorize',
  BILLING_CENTER: '/billing-center',
  CHOOSE_ACCOUNT: '/choose-account',
  DEBUG_DATA: '/debug-data',
  ERROR: '/error',
  FEATURE_FLAGS: '/apps/feature-flags',
  HIERARCHY: '/hierarchy',
  HOME: '/home',
  LOGOUT: '/logout',
  MANAGE_USERS: '/apps/manage/users',
  PARTNER_SIGN_UP: '/eg/partner/sign-up',
  PRODUCT_CONFIGS: '/products/:productId/manage/:productName',
  PRODUCT_CONFIGURATION_PDP: '/products/:productKey/:productId/configure',
  PRODUCT_CONFIGURATION: '/products/:productId/configure',
  PRODUCT_EXEMPLAR_TEST: '/products/controlplane-exemplar-multi-key/controlplane-exemplar-multi-key/',
  PRODUCT_SUPPORT: '/products/:productKey/:productId/support/:pageId',
  PRODUCT_DETAILS: '/products/:productKey/:productId/:tabId*',
  PRODUCT_MANAGEMENT: '/apps/product-management',
  PRODUCT_SELECTION: '/product/:productKey/:stepId',
  PRODUCT_STATUS: '/products/:productKey/:productId/status',
  PRODUCTS: '/products',
  PROFILE: '/profile/:context/:id',
  TEST_SUB_APPS: '/test-sub-apps',
  USER_MANAGEMENT: '/users',
  WELCOME: '/',
};

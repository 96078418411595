import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, makeObservable, observable } from 'mobx';
import { Partner } from '@openworld-ui/sub-app-integration-types';
import { getLocalStorageItem } from 'common/utils/get-local-storage-item';
import { Store } from 'bernie-plugin-mobx';
import { CONTEXT_PARTNER_ROOT } from '../constants';

export class PartnerStore extends Store implements Partner {
  @observable public accountId: string;

  @observable public id: string;

  constructor(logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    this.accountId = '';
    this.id = getLocalStorageItem(CONTEXT_PARTNER_ROOT) || '';
    makeObservable(this);
  }

  hydrate(data: SerializedData): void {
    Object.assign(this, data);
    this.id = getLocalStorageItem(CONTEXT_PARTNER_ROOT) || '';
  }

  @action
  setPartnerAccountId = (accountId: string): void => {
    this.accountId = accountId;
  };
}

import { codeSplit } from 'bernie-core';
import { DefaultController } from './default-controller';
import { EgPagePath } from '../common/utils/eg-page-path';

export class ProductSupportController extends DefaultController {
  public pageTitle = 'Product Support';

  public pageId = 'product-support';

  public path = EgPagePath.PRODUCT_SUPPORT;

  public routeName = 'product-support';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(
    () => import(/* webpackChunkName: "ProductSupport" */ '../views/product-support-page/product-support-page')
  );

  public exact = true;

  public isSandboxEnabled = true;
}

import { codeSplit } from 'bernie-core';
import { DefaultController } from './default-controller';
import { EgPagePath } from '../common/utils/eg-page-path';

export class DebugPageController extends DefaultController {
  public pageTitle = 'Debug';

  public pageId = 'debugData';

  public path = EgPagePath.DEBUG_DATA;

  public routeName = 'debugData';

  public bundles = [];

  public isPartnerProtected = false;

  public isAuthenticated = false;

  public isSandboxEnabled = true;

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(() => import('../views/debug-page/debug_page'));

  public exact = true;
}

import { Request as HapiRequest } from '@hapi/hapi';
import { Request as BernieRequest } from 'bernie-http';
import { post } from '../axios-wrapper';
import { PrincipalTokenResponse } from '../../stores/model/identity-authorization-models';
import { TRACE_ID } from '../../constants';
import { LogFunction } from '../logging/server-logger';

export const getOpaqueToken = (request: BernieRequest | HapiRequest): string | undefined => {
  const cookies = request.headers.cookie.split('; ');
  const sessionToken = cookies?.find((row: string) => row.startsWith('EG_SESSIONTOKEN='));
  return sessionToken ? sessionToken.split('=')[1] : sessionToken;
};

export const fetchPrincipalToken = async (
  request: BernieRequest | HapiRequest,
  userTokenServiceUrl: string,
  log?: LogFunction
): Promise<string | null> => {
  const opaqueToken = getOpaqueToken(request);
  if (!opaqueToken) {
    return null;
  }

  const axiosConfig = {
    url: userTokenServiceUrl,
    data: { opaqueToken },
    operation: 'principal_token',
    traceId: request.headers[TRACE_ID],
  };

  const response = await post<PrincipalTokenResponse>(axiosConfig, log || request.log);

  return response.data.encodedJwt;
};

import { Store } from 'bernie-plugin-mobx';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, makeObservable, observable } from 'mobx';

export interface Banner {
  message?: string;
  heading?: string;
  icon?: string;
}

export class BannerStore extends Store {
  @observable public banner: Banner | null | undefined;

  @observable public bannerLocation: string | null | undefined;

  constructor(logger: Logger = NOOP_LOGGER) {
    super({}, logger);
    this.banner = null;
    this.bannerLocation = null;
    makeObservable(this);
  }

  hydrate(): void {
    this.banner = null;
    this.bannerLocation = null;
  }

  @action
  setBanner(value: Banner | null): void {
    this.banner = value;
  }

  @action
  setBannerLocation(value: string | null): void {
    this.bannerLocation = value;
  }

  @action
  clearBanner(): void {
    this.banner = null;
    this.bannerLocation = null;
  }
}

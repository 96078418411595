import { ClientLogger } from 'bernie-client';
import { SerializedData } from 'bernie-core';
import { Logger, NOOP_LOGGER } from 'bernie-logger';
import { action, computed, makeObservable, observable } from 'mobx';

import { Store } from 'bernie-plugin-mobx';
import { SubAppConfig, subAppsConfig } from 'config/sub-apps';

const TEST_SUB_APP_CONFIG_STORAGE_KEY = 'testSubAppConfigs';

export class SubAppConfigStore extends Store {
  @observable
  readonly indexedTestSubAppConfigs: Map<string, SubAppConfig>;

  private liveSubAppConfigs = subAppsConfig;

  constructor(logger: Logger = NOOP_LOGGER, indexedTestSubAppConfigs: Map<string, SubAppConfig> = new Map()) {
    super({}, logger);
    this.indexedTestSubAppConfigs = indexedTestSubAppConfigs;
    makeObservable(this);
  }

  @computed
  get testSubAppConfigs(): SubAppConfig[] {
    return Array.from(this.indexedTestSubAppConfigs.values());
  }

  @computed
  get subAppConfigs(): SubAppConfig[] {
    // Test sub-app configs overwrite live sub-app configs
    const indexedSubAppConfigs = new Map(Object.entries(this.liveSubAppConfigs));
    this.indexedTestSubAppConfigs.forEach((config, appName) => indexedSubAppConfigs.set(appName, config));
    return Array.from(indexedSubAppConfigs.values());
  }

  public static createForClient(): SubAppConfigStore {
    const logger = ClientLogger.getLoggerWithIdentifier('platformconsole.store.subAppConfig');
    const testSubAppConfigsJson = window.localStorage.getItem(TEST_SUB_APP_CONFIG_STORAGE_KEY);
    const testSubAppConfigs: SubAppConfig[] = testSubAppConfigsJson ? JSON.parse(testSubAppConfigsJson) : [];
    const indexedTestSubAppConfigs = new Map(testSubAppConfigs.map((config) => [config.appName, config]));
    return new SubAppConfigStore(logger, indexedTestSubAppConfigs);
  }

  hydrate(serializedData: SerializedData): void {
    this.liveSubAppConfigs = serializedData.liveSubAppConfigs;
  }

  @action
  addTestSubAppConfig(config: SubAppConfig): void {
    this.indexedTestSubAppConfigs.set(config.appName, config);
    this.persistTestSubAppConfigs();
  }

  @action
  deleteTestSubAppConfig(appName: string): void {
    this.indexedTestSubAppConfigs.delete(appName);
    this.persistTestSubAppConfigs();
  }

  private persistTestSubAppConfigs() {
    window.localStorage.setItem(TEST_SUB_APP_CONFIG_STORAGE_KEY, JSON.stringify(this.testSubAppConfigs));
  }
}

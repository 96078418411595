import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class HomeController extends DefaultController {
  public pageTitle = 'Home';

  public pageId = 'Home';

  public path = EgPagePath.HOME;

  public routeName = 'Home';

  public bundles = [];

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(() => import(/* webpackChunkName: "Home" */ '../views/home-page/home-page'));

  public exact = true;

  public isSandboxEnabled = true;
}

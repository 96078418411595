import { codeSplit } from 'bernie-core';
import { DefaultController } from './default-controller';
import { EgPagePath } from '../common/utils/eg-page-path';

export class ProfileController extends DefaultController {
  public pageTitle = 'Profile';

  public pageId = 'Profile';

  public path = EgPagePath.PROFILE;

  public routeName = 'Profile';

  public bundles = [];

  public component = codeSplit(() => import(/* webpackChunkName: "Profile" */ '../views/profile-page/profile-page'));

  public exact = true;
}

import { SystemEvent, SystemEventLevel } from 'bernie-logger';
import { ClientLogger as BernieClientLogger } from 'bernie-client';
import { Logger } from '@shared-ui/logger-context/dist/commonjs/components/logger';
import { EgConsoleError } from 'common/logging/console-errors';
import { LogDataObject } from './server-logger-utils';
import UserContextSingleton from './global-logging-context-singleton';

const LOGGER_IDENTIFIER = 'eg-console.client';
const NOT_UPPER_CASE_AND_UNDERSCORES_REGEX = new RegExp('[^A-Z_]+');
export const EVENT_NAME_NOT_PROPERLY_FORMATTED = 'EVENT_NAME_NOT_PROPERLY_FORMATTED';
export const NOT_IMPLEMENTED = 'Method not implemented.';

export const SubAppClientLogger = (identifier: string = LOGGER_IDENTIFIER): Logger => {
  const clientLogger = BernieClientLogger.getLoggerWithIdentifier(identifier);

  const validateEventName = (name: string): void => {
    if (NOT_UPPER_CASE_AND_UNDERSCORES_REGEX.test(name)) {
      clientLogger.logEvent(new SystemEvent(SystemEventLevel.ERROR, EVENT_NAME_NOT_PROPERLY_FORMATTED), {
        invalidEventName: name,
      });
    }
  };

  const handleLogging = (eventName: string, level: SystemEventLevel, ...args: LogDataObject[]): void => {
    let argsData = {};
    if (args) {
      [argsData] = args;
    }

    let context = {};
    if (UserContextSingleton) {
      context = {
        ...UserContextSingleton,
      };
    }

    let data = {
      ...context,
      ...argsData,
    };

    if (Object.keys(data).length === 0) {
      data = '';
    }

    clientLogger.logEvent(new SystemEvent(level, eventName), data);
    validateEventName(eventName);
  };

  return {
    event(): void {
      throw new EgConsoleError(NOT_IMPLEMENTED);
    },
    error(eventName: string, args = []): void {
      handleLogging(eventName, SystemEventLevel.ERROR, args);
    },
    info(eventName: string, args = []): void {
      handleLogging(eventName, SystemEventLevel.INFO, args);
    },
    warning(eventName: string, args = []): void {
      handleLogging(eventName, SystemEventLevel.WARN, args);
    },
  };
};

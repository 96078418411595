import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class ProductStatusController extends DefaultController {
  public pageTitle = 'Product Status';

  public pageId = 'ProductStatus';

  public path = EgPagePath.PRODUCT_STATUS;

  public routeName = 'ProductStatus';

  public bundles = [];

  public exact = true;

  public component = codeSplit(
    () => import(/* webpackChunkName: "ProductStatus" */ '../views/product-status-page/product-status-page')
  );

  public isSandboxEnabled = true;
}

import { codeSplit } from 'bernie-core';
import { EgPagePath } from '../common/utils/eg-page-path';
import { DefaultController } from './default-controller';

export class ErrorPageController extends DefaultController {
  public pageTitle = 'Error page';

  public pageId = 'ErrorPage';

  public path = EgPagePath.ERROR;

  public routeName = 'Error page';

  public bundles = [];

  public isPartnerProtected = false;

  public isAuthenticated = false;

  // @ts-ignore Unsure how to fix codeSplit issue
  public component = codeSplit(() => import(/* webpackChunkName: "ErrorPage" */ '../views/error-page/error-page'));

  public exact = true;
}
